<template>
    <b-overlay :show="isLoadingCondominiums">
        <b-table
            responsive
            :items="condos"
            :fields="fields"
            class="mb-0"
            show-empty
            empty-text="No se tiene registros"
        >

            <template #cell(status)="row">
                <span class="text-nowrap">
                    <b-badge pill :variant="row.item.status ? 'success': 'danger'" >
                        {{ row.item.status ? "Activo" : "Desactivado" }}
                    </b-badge>
                </span>
            </template>

            <template #cell(actions)="row">
                <div>
                    <b-button
                        size="sm"
                        variant="primary"
                        :to="{name: 'edit-condominium', params:{ id: row.item.id }}"
                    > <feather-icon icon="Edit2Icon" size="12"  />
                    </b-button>

                    <b-button
                        size="sm"
                        @click="getCondoFeeReport(row.item)"
                        variant="success"
                        :disabled="row.item.id == feeReportID && isloadingCondominalFeesReport"
                        :class="`${{ 'd-none': !row.item.status || row.item.isLoading }} margin-half`"
                    >
                        <b-spinner v-if="row.item.id == feeReportID && isloadingCondominalFeesReport" small/>
                        <feather-icon v-else icon="FileTextIcon" size="12" />
                    </b-button>

                    <b-form-checkbox
                        :class="{ 'd-none': row.item.status }"
                        v-model="row.item.status"
                        v-b-tooltip.hover.v-warning
                        title="Cambiar Status"
                        :value="true"
                        switch
                        inline
                        :unchecked-value="false"
                        @change="changeStatus(row.item, $event)"
                        class="custom-control-success"
                    />
                    <b-spinner label="Loading..." variant="success" v-if="row.item.isLoading"/>
                </div>
            </template>
        </b-table>
    </b-overlay>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { showAlertMessage } from '@/helpers/helpers'

export default {
    props:{
        isLoadingCondominiums:{
            type: Boolean,
            required: true,
        }
    },
    data(){
        return {
            fields: [
                { key: "code", label: "Código", class: "text-center", formatter: value => {return value || 'N/A'}  },
                { key: "name", label: "Nombre", class: "text-center" },
                { key: "address", label: "Dirección", formatter: value => {return value || 'N/A'} },
                { key: "resortsname", label: "Resort" },
                // { key: "bankAccount", label: "Cuenta bancaria" },
                { key: "status", label: 'Status' },
                { key: "actions", label: "Acciones", class: "text-center" },
            ],
            isloadingCondominalFeesReport: false,
            feeReportID: null
        }
    },
    computed:{
        ...mapState('fivesClubCatalogs',['condos']),
        ...mapState('auth', ['user']),
    },
    methods:{
        ...mapActions('fivesClubCatalogs', ['updateCondos','fetchAnnualsCondominalsBudgets','fetchCondominalFeesReport']),

        async changeStatus(item, event){
            item.isLoading = true
            const payload = {
                idUser: this.user.idUser,
                id: item.id,
                name:item.name,
                street:item.street,
                address:item.address,
                zip:item.zip,
                bankAccount:item.bankAccount,
                resorts:item.resorts,
                status: Boolean(event)
            }
            const { status, message } = await this.updateCondos(payload)
            item.isLoading = false
            if (status) showAlertMessage('Ok: Status actualizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
        },
        async getCondoFeeReport(item){
            this.isloadingCondominalFeesReport = true
            this.feeReportID = item.id
            const {id:IdCondo, name} = item
            await this.fetchCondominalFeesReport({name, IdCondo, report: true})
            this.feeReportID = null
            this.isloadingCondominalFeesReport = false
        }
    }
}
</script>
<style scoped>
.margin-half{
    margin: 0.5rem;
}
</style>