<template>
    <div>   
        <HousingNav/>
        <b-card class="mt-1">
            <div class="head-btn">
                <b-button
                    :to="{ name: 'create-condominium'}"               
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"                                  
                >
                    <feather-icon icon="PlusIcon" style="color: white" />
                </b-button>
            </div>  


            <CondominiumsList :isLoadingCondominiums="isLoadingCondominiums"/>
                                                
        </b-card>
    </div>
</template>

<script>

import { mapState, mapActions, mapMutations } from "vuex"
import CondominiumsList from "@/modules/fivesClub/components/catalogs/housing/CondominiumsList"
import Ripple from 'vue-ripple-directive'
import HousingNav from "@/modules/fivesClub/components/catalogs/housing/HousingNav"
    
export default {
    directives: {Ripple, },
    components:{
        HousingNav,
        CondominiumsList
    },
    async created() {
        await this.getInitialData()
    },
    data(){
        return {
            isLoadingCondominiums: false,
        }
    },
    computed: {
        ...mapState('start', ['hotels']),                                   
    },
    methods:{
        ...mapActions('fivesClubCatalogs', ['getInitialContentListHousing']),         
        ...mapMutations('start',['setHotels']),                        
        ...mapMutations('fivesClubCatalogs',['setCondos','setSubCondos','setHousing','setRentalPool','setPaymentTypes','setIsMarkedAllHousing','setRequestHousing']),       
      
        async getInitialData(){
                this.setCondos([])
                this.isLoadingCondominiums = true
                const { condos, subCondos, hotels, rentalPool, paymentTypes } = await this.getInitialContentListHousing();
                this.setCondos(condos)
                this.setSubCondos(subCondos)
                this.setRentalPool(rentalPool)
                this.setPaymentTypes(paymentTypes)
                if( this.hotels.length === 0 ){
                    this.setHotels(hotels)
                }
                this.isLoadingCondominiums = false

            },
    }
};
</script>

<style scoped>
.head-btn{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}
</style>